<template>
  <div>
    <v-card
      v-for="address in userAddresses"
      :key="address.id"
      max-width="250"
      class="mx-4 my-4"
      :style="selectedUserAdd === address.id ?
             'border: 2px solid #FF4C00;' :
             ''"
    >
      <v-card-text>
        <div
          v-show="address.predeterminado"
        >
          Predeterminada
        </div>
        <p class="display-1 text--primary">
          {{ address.calle}}
        </p>
        <div class="text-primary">
          Exterior: {{ address.numero_exterior }} <span v-if="address.numero_interior">/ Interior: {{ address.numero_interior}}</span> <br>

          {{ address.colonia }}<br>
          {{ address.cuidad }} {{ address.codigo_postal}} <br>
          Número de telefono: {{ address.numero_contacto }} 
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          v-if="!selectedAddress"
          text
          color="primary"
          @click="$emit('addressEdit', address)"
        >
          Editar
        </v-btn>
        <v-btn
          v-if="!selectedAddress"
          text
          color="primary"
          @click="$emit('deleteAdd', address.id)"
        >
          Eliminar 
        </v-btn>
        <v-btn
          v-if="selectedAddress"
          text
          color="primary"
          @click="NewAddressSelected(address)"
        >
          Seleccionar 
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
  props: {
    userAddresses: {
      type: Array,
    },
    selectedAddress: Boolean,
    selectedUserAdd: Number,
  },
  methods: {
    ...mapActions('cart', ['distanciaSucursalCliente']),
    ...mapMutations('usuario', ['updateSelectedAddress']),

    NewAddressSelected(address) {
      this.updateSelectedAddress(address)
      this.distanciaSucursalCliente()
      this.$emit('selectedAdd', address)
    }
  }
  
}
</script>